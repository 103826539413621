<script>
import SelectCoupon from '../../SelectCoupon.vue';
import moment from 'moment'
import axios from 'axios'
import config from '@/config.js'
export default {
  props: [   
    "data",
    "payload",
    "discountLists",
    "cartSummary",
    "totalQuantity",
    "totalPrice",
    "price"     
  ],
  components: {
    SelectCoupon,
  },
  data() {
    return {
      modal: false ,
      searchCoupon:'',
      coupons:[],
      applied_discount:false,     
      moment
    }
  },
  mounted(){
    this.coupons=this.discountLists
  },
  watch:{
   
  },
  methods:{   
    async applyCoupon(){
      this.applied_discount=true
      const search=this.searchCoupon.length > 3 ? this.searchCoupon :'';
      const response = await axios.get(config.api +"product/discountList/"+this.data._id+"?offset="+new Date().getTimezoneOffset()+"&user_id="+this.data.user_id+"&search="+search+"&includeHiden=true");      

      if (response && response.data.success) {
        // Step 1: Get fields with non-zero counts
        const couponExist = Object.keys(response.data.count).filter(key => response.data.count[key] > 0);

        // Step 2: Collect matching data as an array
        const couponData = couponExist
          .flatMap(field => (Array.isArray(response.data.data[field]) ? response.data.data[field] : []));

        const discountLists = couponData.filter(d=>d.discount_value.applies_to.type=='spcific_products' && d.discount_value.applies_to.products.length > 0 ? d.discount_value.applies_to.products.indexOf(this.data._id) > -1 : true);

       const discount= discountLists.find(x=>x.title==search)
        
        if(discount){
          this.updateDiscountLists(discountLists,false)  
          // const isApply=this.checkdisabled(discount)  
          // if(isApply){
          //   this.$emit('selectDiscount',discount)
          // }       
          this.$emit('selectDiscount',discount)    
          this.coupons=this.discountLists
          this.searchCoupon=''
        } else {
          this.$vs.notification({
            color: "danger",
            position: "top-right",
            text: this.$t('coupon.Couponnotfound'),//"Please fix fields error before proceeding to next step",
            duration: 2000,
          });
        }
       
      }else{
          this.coupons=[]
        }
    
    },
    checkdisabled(discount) {            
      let discount_amount=0;    
   
      let price =  this.data.price;  
      let totalQuantity=this.totalQuantity;

      if(this.data.cartSummary && this.data.cartSummary.length > 0){
          totalQuantity=0;
          price = 0;
          this.data.cartSummary.map(async (e) => {      
            totalQuantity = totalQuantity + e.qty;     
            let subPrice = (e.price ? e.price : e.priceOriginal) * e.qty;
              if (e.whosaleData) {
                e.whosaleData.map((one) => {
                  if (one.count && parseInt(one.count) <= parseInt(e.qty)) {
                    subPrice = one.price * parseInt(e.qty);
                  }               
                });
              }             
              price = price + subPrice;     
           
        });
      }      
     if(discount.minimum_requirements.type=='minimum_purchase'){         
        if(Number(discount.minimum_requirements.value)>this.totalPrice){
          return discount_amount > 0 
        }        
      }else if(discount.minimum_requirements.type=='minimum_quantity'){       
        if(Number(discount.minimum_requirements.value)>totalQuantity){
          return discount_amount > 0 
        }        
      }       

      if(discount.payment_type=='cod' || this.payload.payment=='COD'){           
        if(this.payload.payment!='COD' && discount.payment_type=='cod'){        
          return discount_amount > 0 
        }else if(this.payload.payment=='COD' && discount.payment_type=='non_cod'){        
          return discount_amount > 0 
        }        
      }  

      if(discount.discount_type=="moneyOffProduct"){


          if(discount.discount_value.type=="fixed"){                   
            discount_amount=(Number(discount.discount_value.value));           
          }else{
            discount_amount =(price *Number(discount.discount_value.value)) / 100;                                        
                           
          }       
          
        }else if(discount.discount_type=="moneyOffOrder"){
          if(discount.discount_value.type=="fixed"){                   
            discount_amount=Number(discount.discount_value.value);         
          }else{
            discount_amount =(this.totalPrice *Number(discount.discount_value.value)) / 100;                             
                    
          }  
        }else if(discount.discount_type=="moneyOffShipping"){
          if (this.payload.deliveryPrice) {
            if (discount.discount_value.applies_to.shipping_type=='all' || discount.discount_value.applies_to.shipping_type==this.payload.deliveryCourier) {
              if (discount.discount_value.type=="fixed"){                   
                discount_amount=Number(discount.discount_value.value);         
              } else{
                discount_amount =(this.payload.deliveryPrice *Number(discount.discount_value.value)) / 100;    
              }

              return discount_amount > 0    
            }
          }

          return true;
        }     
    
        return discount_amount > 0        
    },
    clearDiscountCode(){
      this.searchCoupon=''
      this.$emit('clearDiscountCode')
    },
    selectDiscount(discount){
      this.$emit('selectDiscount',discount)
    },
    updateDiscountLists(discounts,replace){
      this.$emit('updateDiscountLists',discounts,replace)
    },

  }
}
</script>

<template>
  <div class="coupon">
    <div class="flex items-center gap-16">
      <img src="@/assets/images/illustrations/icon-illustration-coupon.svg" alt="O">
      <div class="w-100">
        <p class="fs-14 fw-700 color-primary">{{$t('coupon.HaveCouponCode')}}</p>
        <div class="flex items-start gap-16" style="flex-wrap: wrap; row-gap: 8px;">
          <div style="flex: 1 0 160px;">
            <vs-input v-model="searchCoupon" :class="{'is-invalid':searchCoupon!='' && this.coupons.length==0 && applied_discount}" class="vs-form-control w-100" :placeholder="$t('coupon.entercouponhere')"></vs-input>
            <span  v-if="searchCoupon!='' && this.coupons.length==0 && applied_discount" class="invalid-feedback-custom">{{$t('coupon.Couponnotfound')}}</span>
          </div>
          <button @click="applyCoupon" :disabled="searchCoupon.length < 4" class="pp-button-green--fill">{{$t('coupon.Use')}}</button>
        </div>
      </div>
    </div>
    <div class="coupon__applied"  v-if="payload.automaticDiscount.code || payload.discount.code">
    <p class="fs-14 fw-600 m-0 color-primary">{{$t('coupon.Usedcoupons')}}</p>
    <template v-if="payload.automaticDiscount.code && !isNaN(payload.automaticDiscount.amount)">      
      <div class="flex items-start" style="gap: 8px">
        <img src="@/assets/icons/check-success.svg" alt="✔︎" width="20px" height="20px">
        <div class="flex-grow-1">         
          <p class="fs-14 fw-700 m-0" style="color: #14AD4D;">
            {{ payload.automaticDiscount.code }}
            <span class="color-mute">(Kupon Otomatis)</span>
          </p>
          <template v-if="payload.automaticDiscount.discount_value.type === 'fixed'">
            <p class="fs-14 fw-400 m-0 color-primary">{{$t('coupon.Discount')}} {{ 'RP' +Number(payload.automaticDiscount.discount_value.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</p>

          </template>
          <template v-else>
            <p class="fs-14 fw-400 m-0 color-primary">{{$t('coupon.Discount')}} {{ payload.automaticDiscount.discount_value.value ? payload.automaticDiscount.discount_value.value + "% " : "" }}
            </p>
          </template>   
         <template v-if="payload.automaticDiscount.minimum_requirements.type == 'minimum_purchase'">
            <p  class="fs-13 fw-400 m-0 color-mute">{{ payload.automaticDiscount.minimum_requirements.value ? `Minimum purchase of RP
              ${Number(payload.automaticDiscount.minimum_requirements.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :
              $t('coupon.NoMinPurchaseRequirement') }}</p>
          </template>
          <template v-else-if="payload.automaticDiscount.minimum_requirements.type == 'minimum_quantity'">
            <p  class="fs-13 fw-400 m-0 color-mute">{{ payload.automaticDiscount.minimum_requirements.value ? `Minimum purchase of
              ${payload.automaticDiscount.minimum_requirements.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} items` : $t('coupon.NoMinPurchaseRequirement') }}</p>

            </template>
            <template v-else>
            <p  class="fs-13 fw-400 m-0 color-mute">{{ $t('coupon.NoMinPurchaseRequirement') }}</p>
          </template>
          <p class="fs-13 fw-400 m-0 color-mute" v-if="payload.automaticDiscount.end_date">                   
            {{ payload.automaticDiscount.end_date ? moment(payload.automaticDiscount.full_end_date).diff(moment(payload.automaticDiscount.full_start_date), 'days')  > 0 ? "Until "+moment(payload.automaticDiscount.full_end_date).diff(moment(payload.automaticDiscount.full_start_date), 'days')+" days" : 'Until today' : '' }}
          </p>
        </div>
      </div>
    </template>
    <template v-if="payload.discount.code && !isNaN(payload.discount.amount)">
      <div class="flex flex-column" :class="{'bordered-selected-coupon': payload.discount.discount_type === 'moneyOffShipping' && (payload.deliveryPrice == 0 || payload.deliveryCourier == '')}" >
      <div class="flex items-start" style="gap: 8px">
        <template v-if="payload.discount.discount_type === 'moneyOffShipping'">
            <img v-if="payload.deliveryPrice == 0 || payload.deliveryCourier == ''" src="@/assets/icons/TuktukTheme/cross-pink.svg" alt="✔︎" width="18px" height="18px">
            <img v-else src="@/assets/icons/check-success.svg" alt="✔︎" width="20px" height="20px">
        </template>
        <template v-else>
          <img src="@/assets/icons/check-success.svg" alt="✔︎" width="20px" height="20px">
        </template>
        <div class="flex-grow-1">
          <p class="fs-14 fw-700 m-0" style="color: #14AD4D;">{{ payload.discount.code }}</p>      

          <template v-if="payload.discount.discount_value.type === 'fixed'">
            <p class="fs-14 fw-500 m-0 color-primary">{{$t('coupon.Discount')}} {{ 'RP' +Number(payload.discount.discount_value.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</p>

          </template>
          <template v-else>
            <p class="fs-14 fw-500 m-0 color-primary">{{$t('coupon.Discount')}} {{ payload.discount.discount_value.value ? payload.discount.discount_value.value + "% " : "" }}
            </p>
          </template>   
         <template v-if="payload.discount.minimum_requirements.type == 'minimum_purchase'">
            <p  class="fs-13 fw-400 m-0 color-mute">{{ payload.discount.minimum_requirements.value ? `Minimum purchase of RP
              ${Number(payload.discount.minimum_requirements.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :
              $t('coupon.NoMinPurchaseRequirement') }}</p>
          </template>
          <template v-else-if="payload.discount.minimum_requirements.type == 'minimum_quantity'">
            <p  class="fs-13 fw-400 m-0 color-mute">{{ payload.discount.minimum_requirements.value ? `Minimum purchase of
              ${payload.discount.minimum_requirements.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} items` : $t('coupon.NoMinPurchaseRequirement') }}</p>

            </template>
            <template v-else>
            <p  class="fs-13 fw-400 m-0 color-mute">{{ $t('coupon.NoMinPurchaseRequirement') }}</p>
          </template>
          <p class="fs-13 fw-400 m-0 color-mute" v-if="payload.discount.end_date"> 
            Valid {{ moment(payload.discount.full_start_date).format('DD/MM/YYYY') }} - {{ moment(payload.discount.full_end_date).format('DD/MM/YYYY') }}
          </p>


        </div>
        <img role="button" src="@/assets/icons/ic-close.svg" alt="x" @click="clearDiscountCode">
      </div>
      <div v-if="payload.discount.discount_type === 'moneyOffShipping' && (payload.deliveryPrice == 0 || payload.deliveryCourier == '')" class="flex mt-1" style="gap: 8px;">
        <img src="@/assets/icons/TuktukTheme/alert-circle-pink.svg" height="18px" alt="0">
        <div style="
          font-size: 11px;
          font-weight: 400;
          color: #F53535;
          ">{{ $t('coupon.alertShippingCouponShouldbeFilledAddress') }}
        </div>
      </div>
      </div>
    </template>
    </div>  
    <button @click="modal = true" class="pp-button-green--fill pp-button-green--smooth w-100">{{$t('coupon.ViewMoreCoupons')}}</button>
    <SelectCoupon v-model="modal" theme="classic" :data="data" :payload="payload" @updateDiscountLists="updateDiscountLists"  @selectDiscount="selectDiscount" @clearDiscountCode="clearDiscountCode" :discountLists="discountLists" :totalQuantity="totalQuantity" :cartSummary="cartSummary" :totalPrice="totalPrice" :price="price" />    
  </div>
</template>
 

<style lang="scss" scoped>
.bordered-selected-coupon {
  background: #FEEBEB80;
  padding: 8px 4px;
  border-radius: 8px;
}

button {
  height: 40px;
  color: #FFF;
  border: none;
  border-radius: 8px;
  border-color: #14AD4D !important;
}

.color-primary {
    color: #203551 !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.gap-16 {
  gap: 16px;
}

.color-mute {
  color: #6C7E95
}

[role=button] {
  cursor: pointer;
}
.coupon {
  padding: 16px;
  background-color: #F6F8FC;
  border: 1px solid #E0E9F4;
  border-radius: 8px;

  p {
    margin-bottom: 6px;
  }

  .is-invalid {
    &::v-deep {
      input {
        border-color: #E12121 !important;
      }
    }
  }

  .invalid-feedback-custom {
    color: #E12121;
    margin-top: 6px;
    font-size: 12px;
    font-weight: 400;
    margin-top: 6px;
    display: inline-block;
  }

  .pp-button-green {
    &--fill {
      flex-shrink: 0;
      width: 116px;
      font-size: 14px;
      font-weight: 500;
      background: #14AD4D;
      @media screen and (max-width: 447px) {
        width: 100%;
      }
    }
    &--smooth {
      margin-top: 16px;
      font-size: 14px;
      font-weight: 700;
      color: #14AD4D;
      background: #EBFBF1;
      border: 1px solid #14AD4D; 
    }
  }

  &__applied {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    padding: 16px;
    background: #FFF;
    border: 1px solid #E0E9F4;
    border-radius: 8px;
  }

  &::v-deep {
    input {
      border: 1px solid #E0E9F4;
      box-shadow: 0px 1px 2px 0px #1018280D;
      font-size: 14px;
    }
    .vs-input__label {
      font-weight: 400;
      left: 15px;
    }
  }
}
</style>