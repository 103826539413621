<script>
import 'swiper/swiper-bundle.min.css'
import Swiper, { Navigation, Pagination } from 'swiper'
import { getSizedImageUrl } from "@/helpers/imagekitHelper";

export default {
  props: {
    title: {
      type: String,
      default: () => 'Testimonial',
    },
    theme: {
      type: String,
      default: () => 'Classic',
      validator: function (value) {
        return ['Classic', 'Tuktuk', 'TuktukStep1'].includes(value)
      }
    },
    templateType: String,
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isModalOpen: false,
    }
  },
  computed: {
    buttonPrev() {
      switch (this.theme) {
        case 'Classic':
          return require('@/assets/icons/slick-left-arrow-testi-new.png');
        case 'Tuktuk':
        case 'TuktukStep1':
          return require('@/assets/icons/TuktukTheme/ic-nav-tuktuk--prev-circle-pink.png');
        default:
          return require('@/assets/icons/slick-left-arrow-testi-new.png');
      }
    },
    buttonNext() {
      switch (this.theme) {
        case 'Classic':
          return require('@/assets/icons/slick-right-arrow-testi-new.png');
        case 'Tuktuk':
        case 'TuktukStep1':
          return require('@/assets/icons/TuktukTheme/ic-nav-tuktuk--next-circle-pink.png');
        default:
          return require('@/assets/icons/slick-right-arrow-testi-new.png');
      }
    },
  },
  methods: {
    getSizedImageUrl,
    openModal(index) {
      this.isModalOpen = true;

      // use timeout to wait for element modal being rendered
      setTimeout(() => {
        const modules = [Navigation]
        let swiperConfig = {
          initialSlide: index,
          slidesPerView: "auto",
          freeMode: true,
          modules,
          navigation: {
            nextEl: '.modal-swiper-button-next',
            prevEl: '.modal-swiper-button-prev',
          },
          loop: ['Tuktuk', 'TuktukStep1'].includes(this.theme)
        }

        if (['Tuktuk', 'TuktukStep1'].includes(this.theme)) {
          modules.push(Pagination)
          swiperConfig = {
            ...swiperConfig,
            pagination: {
              el: '.testi-swiper-pagination',
              type: 'bullets',
              clickable: true
            },
          }
        }

        this.modalSwiper = new Swiper(this.$refs['modal-swiper'], swiperConfig)
      }, 50)
    }
  },
  mounted() {
    this.swiper = new Swiper('.swiper-testimonial', {
      slidesPerView: "auto",
      freeMode: true,
      modules: [Navigation],
      navigation: {
        nextEl: '.testi-swiper-button-next',
        prevEl: '.testi-swiper-button-prev',
      },
      on: {
        afterInit(swiper) {
          if (swiper.isBeginning && swiper.isEnd) {
            swiper.navigation.nextEl.classList.add('hide')
            swiper.navigation.prevEl.classList.add('hide')
          } else {
            swiper.navigation.nextEl.classList.remove('hide')
            swiper.navigation.prevEl.classList.remove('hide')
          }
        }
      }
    })
  },
}
</script>

<template>
  <div class="testimonial" :data-theme="theme" :data-template-type="templateType">
    <div class="testimonial__header">
      <p class="testimonial__header__title">{{ title }}</p>
      <div class="testimonial__header__nav">
        <button class="testi-swiper-button-prev">
          <img :src="buttonPrev" alt="left-arrow" />
        </button>
        <button class="testi-swiper-button-next">
          <img :src="buttonNext" alt="right-arrow" />
        </button>
      </div>
    </div>
    <div class="swiper swiper-testimonial testimonial__content">
      <div class="swiper-wrapper">
        <img
          v-for="(image, index) in images"
          :key="index"
          class="swiper-slide"
          @click="openModal(index)"
          :src="getSizedImageUrl(image,'fullView','product-images')"
          alt="Testimonial"
        >
      </div>
    </div>

    <!-- TESTIMONIAL CAROUSEL MODAL -->
    <vs-dialog
      v-if="theme === 'Classic'"
      overflow-hidden
      width="680px"
      v-model="isModalOpen"
      class="pp-modal-carousel-testimoni"
    >
      <div class="pp-modal-carousel-testimoni__container" :data-theme="theme">
        <div ref="modal-swiper" class="swiper">
          <div class="swiper-wrapper">
            <img
              v-for="(image, index) in images"
              :key="index"
              class="swiper-slide"
              @click="openModal(index)"
              :src="getSizedImageUrl(image,'fullView','product-images')"
              alt="Testimonial"
            >
          </div>
          <button class="modal-swiper-button-prev">
            <img src="@/assets/icons/slick-left-arrow-new.png" alt="left-arrow" />
          </button>
          <button class="modal-swiper-button-next">
            <img src="@/assets/icons/slick-right-arrow-new.png" alt="right-arrow" />
          </button>
        </div>
      </div>
    </vs-dialog>

    <vs-dialog
      v-if="['Tuktuk', 'TuktukStep1'].includes(theme)"
      overflow-hidden
      v-model="isModalOpen"
      class="pp-modal-carousel-testimoni theme--tuktuk__modal-testimoni"
    >
      <div class="theme--tuktuk__modal__container pp-modal-carousel-testimoni__container">
        <div class="theme--tuktuk__modal__header">
          <h4>{{ $t('common.Testimonials') }}</h4>
        </div>
        <div ref="modal-swiper" class="swiper theme--tuktuk__modal__main">
          <div class="swiper-wrapper">
            <img
              v-for="(image, index) in images"
              :key="index"
              class="swiper-slide"
              @click="openModal(index)"
              :src="getSizedImageUrl(image,'fullView','product-images')"
              alt="Testimonial"
            >
          </div>
          <button class="modal-swiper-button-prev">
            <img src="@/assets/icons/TuktukTheme/ic-nav-tuktuk--prev-circle-white.svg" alt="left-arrow" />
          </button>
          <button class="modal-swiper-button-next">
            <img src="@/assets/icons/TuktukTheme/ic-nav-tuktuk--next-circle-white.svg" alt="right-arrow" />
          </button>
        </div>
        <div class="theme--tuktuk__modal__footer">
          <div class="testi-swiper-pagination"></div>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>

<style lang="scss">
.testimonial {
  user-select: none;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    &__title {
      margin: 0 !important;
    }

    &__nav {
      display: flex;
      align-items: center;
      gap: 4px;

      button {
        all: unset;
        user-select: none;

        &::after {
          content: unset;          
        }

        &.hide {
          display: none;
        }
      }
    }
  }

  &[data-theme=Classic] {
    .testimonial__header {
      margin-bottom: 20px;

      &__title {
        color: #203551;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }

      &__nav {
        button {
          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }

    .testimonial__content {
      img.swiper-slide {
        aspect-ratio: 1/1;
        object-fit: cover;
        width: 30%;
        min-width: 120px;
        max-width: 192px;
        border-radius: 8px;

        &:not(:last-of-type) {
          margin-right: 16px;
        }
      }
    }

    &[data-template-type=NoSidebar] {
      .testimonial__content {
        img.swiper-slide {
          min-width: 0;
          max-width: 98px;
        }
      }
    }
    
    &[data-template-type=NoSidebarNarrowCanvas] {
      .testimonial__content {
        img.swiper-slide {
          min-width: 0;
          max-width: 67px;
        }
      }
    }
  }

  &[data-theme=Tuktuk],
  &[data-theme=TuktukStep1] {
    padding: 10px 15px;
    .testimonial__header {
      margin-bottom: 12px;

      &__title {
        margin-top: 0;
        color: #15171c;
        font-family: TikTokDisplayMedium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        letter-spacing: .28px;
        margin-bottom: 12px;
      }

      &__nav {
        gap: 10px;

        button {
          background: rgba(235, 67, 91, .2);
          border-radius: 100%;
          display: flex;
          border: 0;
          width: 24px;
          height: 24px;

          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }

    .testimonial__content {
      margin: 0 -15px;

      img.swiper-slide {
        aspect-ratio: 1/1;
        object-fit: cover;
        width: 84px;
        border-radius: 4px;
        margin: 0 4px;

        &:first-child {
          margin-left: 15px;
        }

        &:last-child {
          margin-right: 15px;
        }
      }
    }
  }
}

.pp-modal-carousel-testimoni__container {
  .swiper {
    position: relative;
  }

  &[data-theme=Classic] {
    img.swiper-slide {
      aspect-ratio: 1/1;
      object-fit: contain;
      width: 100%;
      max-width: 660px;
      border-radius: 8px;
      background-color: #F9F7FA;
    }

    .modal-swiper-button-prev,
    .modal-swiper-button-next {
      all: unset;
      background-color: rgba(0, 0, 0, 0.35);
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &:disabled {
        opacity: 0.5;
      }
    }

    .modal-swiper-button-next {
      right: 0;
    }
  }
}

.pp-modal-carousel-testimoni {
  .vs-dialog{
    width: 630px;
  }
  .vs-dialog__close {
    top: 25px !important;
    right: 25px !important;
    left: unset;
    bottom: unset;
    background: unset;
    box-shadow: none;
    &:hover{
      transform: unset;
    }

    .vs-icon-close {
      background-color: transparent;
      position: relative;
      &::before,
      &::after {
        top: 17px;
        right: 9px;
        left: unset;
        bottom: unset;
        background: #6C7E95;
      }
    }
  }

  .vs-dialog__content {
    padding: 10px;
    margin: 0;
    &.notFooter{
      margin: 0;
    }
  }
}

// Tuktuk Modal

.theme--tuktuk__modal-testimoni {
  & .vs-dialog {
    border-radius: 8px !important;
    margin: auto !important;
    width: 100% !important;
    max-width: 285px !important;
    min-width: unset;

    @media screen and (max-width: 574px) {
      max-width: calc(100% - 50px) !important;
    }

    &__close {
      top: 14px !important;
      right: 15px !important;

      & .vs-icon-close {
        width: 20px;
        height: 20px;

        &::before,
        &::after {
          top: 9px;
          right: 3px;
          height: 1px;
          background-color: #000;
        }
      }
    }

    &__content {
      padding: 0px;
    }

    .theme--tuktuk__modal__header h4 {
      font-family: TikTokDisplayMedium;
      text-align: left;
      font-weight: 700;
      font-size: 15px;
      margin: 14px 15px;
    }

    .theme--tuktuk__modal__main {
      background-color: #f9f7fa;
      height: 212px;

      .swiper-slide {
        object-fit: contain;
      }

      .modal-swiper-button-prev,
      .modal-swiper-button-next {
        padding: 0;
        background-color: transparent;
        height: auto;
        width: auto;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        box-shadow: 0px -14px 30px rgba(0, 0, 0, 0.05);
        z-index: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        border: none;
        border-radius: 100px;

        &:disabled {
          opacity: 0.5;
        }
      }

      .modal-swiper-button-next {
        left: unset;
        right: 10px;
      }
    }

    .theme--tuktuk__modal__footer {
      position: relative;
      height: 38px;

      .testi-swiper-pagination {
        left: 0 !important; // override from ProductImage.vue
        display: inline-flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        height: 100%;

        .swiper-pagination-bullet {
          margin: 0 10px;
          flex-shrink: 0;

          &-active {
            background-color: #eb435b !important;
          }
        }
      }
    }
  }
}
</style>